<template>
  <div>
    <div class="auth-wrapper auth-v1 px-2">
      <div class="auth-inner py-2">

        <!-- Login v1 -->
        <b-card class="mb-0">
          <div class="text-right list-unstyled mb-1">
            <locale />
          </div>

          <b-link class="brand-logo">
            <b-img
              :src="appLogoImage"
              style="max-height: 100px"
              alt="logo"
            />
          </b-link>
          <b-card-title class="mb-1">
            {{ $t('forgot_password.card_title') }} 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('forgot_password.card_text') }}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="forgotPasswordForm" #default="{invalid}">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group>
                <label for="forgot-password-email"><span class="text-danger text-bold">*</span> {{ $t('forgot_password.lbl_email') }}</label>
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false: userEmail != null ? true : null"
                    name="forgot-password-email"
                    placeholder="alikaplan@geribildir.io"
                  />
                  <small
                    v-if="errors[0]"
                    class="text-danger"
                  >{{ $t('core.required') }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                {{ $t('forgot_password.btn_send_reset_link') }}
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon" /> {{ $t('forgot_password.back_to_login') }}
            </b-link>
          </p>

        </b-card>
        <b-card class="mt-1 text-center">
          {{ $t('forgot_password.dont_you_have_an_account') }} <b-link :to="{name:'auth-register'}">
            {{ $t('forgot_password.btn_register') }}
          </b-link>
        </b-card>
        <!-- /Login v1 -->
      </div>
    </div>

    <!-- Footer -->
    <footer
      class="footer footer-light"
      :class="static"
    >
      <slot name="footer">
        <app-footer />
      </slot>
    </footer>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard, BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton, BAlert, VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Locale from '@/@core/layouts/components/app-navbar/components/Locale.vue'
import AppFooter from '@core/layouts/components/AppFooter.vue'
import { $themeConfig } from '@themeConfig'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    AppFooter,
    BCard,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
    Locale,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: null,
      // validation
      required,
      email,
    }
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
  methods: {
    validationForm() {
      this.$refs.forgotPasswordForm.validate().then(success => {
        if (success) {
          useJwt.forgetPasswordSendEmail({
            email: this.userEmail,
          })
            .then(response => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('forgot_password.toast_title_password_change_link_sent_to_your_email_address'),
                  icon: 'MailIcon',
                  variant: 'success',
                  text: this.$t('forgot_password.toast_text_context', { email_address: this.userEmail }),
                },
              })
              this.$router.push({ name: 'auth-login' })
            })
            .catch(error => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('core.error'),
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: error.response.data.detail != undefined ? error.response.data.detail : error.response.data,
                },
              })
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
